.container{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 1rem;
}
.item{
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 2rem;
}
.image{
    height: 300px;
    width: 150px;
    margin: 1rem;
}
p{
    font-size: large;
    font-weight: normal;
}
.button{
    color: black;
}
