.container{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    color: white;
}
h1{
    font-size: x-large;
}
.one{
    background-color: white;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* justify-content: center; */
    align-items: center;
    margin: 1rem;
    border-radius: 20px;
    color: black;
}
.matter{
    font-size: large;
    font-weight: bold;
    margin-right: 0.3rem;
}
.image{
    width: 200px;
    height: 300px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
     border-radius: 30%;
    max-width: 100%;
    min-width: 200px;
}
.subimg{
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 0px;
}
.two{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.missionmatter{
    margin: 1rem;
}
.mission{
    background-color: white;
    margin: 1rem;
    max-width: 80%;
    color: black;
    font-size: large;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
}
.services{
    background-color: white;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 3rem;
    border-radius: 1.25rem;
}
.serviceimg{
    height: 300px;
    width: 300px;
    margin: 1rem;
}
h1{
    font-size: xx-large;
}
.faqitem{
    background-color: white;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    color: black;
    margin: 1rem;
}
.faq{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 90%;
}
.question{
    margin-left: 1.2rem;
}
.list{
    margin: 1.25rem;
    font-size: 1.75rem;
}
@media screen and (max-width:500px) {
 .list{
    font-size: 1rem;
 }   
}